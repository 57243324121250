.footer{
  background-image: url('../../images/subsite/footer-foto.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-top: 100px;
  padding-top: 125px;
  padding-bottom: 125px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @include mq($until: xl) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @include mq($until: lg) {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 50px;
  }
  &__slogan{
    color: #FFFFFF;
    font-weight: 200;
    text-transform: uppercase;
    font-family: 'Montserrat', serif;
    width: 50%;
    font-size: rem(38px);
    line-height: 1.4;
    @include mq($until: xl) {
      font-size: rem(32px);
    }
    @include mq($until: lg){
      font-size: rem(28px);
    }
    @include mq($until: md){
      width: 100%;
    }
    @include mq($until: xs){
      font-size: rem(24px);
    }
  }
  &__contact{
    width: 40%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    @include mq($until: md){
      width: 100%;
      margin-top: 30px;
      grid-gap: 15px;
    }
    &-item{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: transform .3s ease-in-out;
      transform-origin: left center;
      &:hover{
        transform: scale(0.95);
      }
      img{
        @include mq($until: xs){
            max-width: 35px;
        }
      }
      span{
        color: #FFFFFF;
        margin-left: 20px;
        font-size: rem(24px);
        @include mq($until: lg){
          font-size: rem(22px);
        }
        @include mq($until: xs){
          margin-left: 15px;
        }
      }
    }
  }
}