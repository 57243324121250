.opinions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  background-color: $color-sections;
  align-items: flex-start;
  padding-top: 100px;
  padding-bottom: 50px;

  @include mq($until: lg){
    padding-top: 50px;
  }
  @include mq($until: md) {
    grid-template-columns: .5fr;
    justify-content: center;
    height: auto;
    margin-top: 0;
    margin-bottom: 20px;
    padding-top: 50px;
  }

  @include mq($until: sm) {
    grid-template-columns: .7fr;
  }

  @include mq($until: xs) {
    grid-template-columns: 1fr;
    padding-top: 30px;
  }
}
.opinion{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: black;
  height: 100%;

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    img{
      max-height: 150px;
    }
  }

  &__quote {
    background-color: white;
    padding: 30px 70px;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
    height: 100%;
    @include mq($until: xl) {
      padding: 30px 40px;
    }

    p {
      color: $color-main;
      font-weight: 500;
      position: relative;
      @include mq($until: xl) {
        font-size: rem(14px);
      }
      &::before {
        content: '';
        position: absolute;
        background-image: url('../../images/quote.png');
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 40px;
        width: 20px;
        top: -20px;
        left: -25px;
      }

      &::after {
        content: '';
        position: absolute;
        background-image: url('../../images/quote.png');
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 40px;
        width: 20px;
        bottom: 3px;
        right: -15px;
      }
    }
  }
  &__project-name{
    @extend .opinion__quote;
    p::after, p::before{
      display: none;
    }
  }

}