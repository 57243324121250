.footer-small{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;

  @include mq($until: md) {
    flex-direction: column;
    justify-content: center;
    & > * {
        margin-bottom: 10px;
    }
  }

  a {
     display: flex;
     align-items: center;

     img {
       margin-left: 10px;
     }
   }

  &__ssi{
    img{
      filter: grayscale(1);
      transition: filter .3s ease-in-out;
    }
    &:hover img{
      filter: none;
    }
  }
}
.copyright{
    display: flex;
    align-items: center;
}