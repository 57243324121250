.blog {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url('../../images/blog-bg.jpg');
  background-color: $color-main;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-top: 100px;
  padding-bottom: 100px;

  @include mq($until: md) {
    height: auto;
    margin: 50px 0;
    background-image: none;
    background-color: #f0f0f0;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;
  }

  &__header {
    @extend .faq-section__header;
    margin-bottom: 7vw;
  }

  &__posts-container {
    display: grid;
    grid-gap: 30px;
    row-gap: 60px;
    grid-template-columns: repeat(4, 1fr);

    @include mq($until: xl){
      grid-template-columns: repeat(3, 1fr);
    }

    @include mq($until: lg) {
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
    }

    @include mq($until: xs) {
      grid-template-columns: 1fr;
      justify-content: center;
      row-gap: 30px;
    }

    &--homepage{
      @include mq($until: xl) {
        .post:nth-of-type(4){
          display: none;
        }
      }
      @include mq($until: xl) {
        .post:nth-of-type(4){
          display: flex;
        }
      }
    }
  }

  &__link {
    @extend .faq-section__link;
  }
}

.post {
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 10px 38px -9px rgba(0,0,0,0.25);
  transition: box-shadow .3s, transform .3s;
  background-color: $color-main;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    box-shadow: 0 10px 38px 0 rgba(0,0,0,0.35);
    transform: translateY(-20px);
  }

  h4 {
    color: white;
  }

  &--homepage{
    background-color: #fff;
    h4{
      color: $color-main;
    }
  }

  &__link {
    color: white;
    font-weight: 600;
    text-align: right;
    &--homepage{
      color: $color-main;
    }
  }

  &__excerpt {
    color: white;
    margin-bottom: 10px;
    &--homepage{
      color: $color-main;
    }
  }

  &__title {
    color: $color-main;
  }

  &__content-container {
    margin-top: 75px;
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    grid-column-gap: 50px;
    grid-row-gap: 25px;

    @include mq($until: md) {
      grid-template-columns: 1fr;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    grid-column: 1 / 3;
  }

  &__img {
    @include mq($until: md) {
      grid-row: 3/4;
    }
  }

  &__date-container {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }

  &__date {
    font-size: 13px;
    font-style: italic;
    font-weight: 600;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;

  li::before {
    display: none;
  }

  .active {
    color: $color-main;
    font-weight: 600;
  }
}