.faq-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url('../../images/foto-sek4.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include mq($until: xl) {
    height: auto;
    padding-top: 100px;
  }
  @include mq($until: md) {
    height: auto;
    margin: 50px 0;
    background-image: none;
    background-color: #f0f0f0;
    padding-top: 50px;
  }
  @include mq($until: xs){
    padding-top: 30px;
  }

  &__container {
    display: grid;
    grid-gap: 30px;
    row-gap: 60px;
    grid-template-columns: repeat(4, 1fr);
    @include mq($until: xl) {
      grid-gap: 15px;
    }
    @include mq($until: lg){
      grid-template-columns: repeat(2, 1fr);
    }
    @include mq($until: md) {
      grid-template-columns: 450px;
      justify-content: center;
    }

    @include mq($until: xs) {
      grid-template-columns: 1fr;
      justify-content: center;
    }
  }

  &__header{
    color: #FFFFFF;
    font-size: rem(28px);
    text-shadow: 1.5px 3px 10px #000;
    margin-bottom: 7.8vw;
    text-align: left;
    @include mq($until: md){
      text-shadow: none;
      text-align: center;
      color: $color-main;
    }
  }

  &__link {
    margin: 50px 0;
    align-self: center;
    color: white;
    padding: 8px 15px;
    transition: background-color .3s ease-in-out, color .3s ease-in-out;
    @include mq($until: md){
      color: $color-main;
    }
    &:hover{
      background-color: $color-main;
      color: #ffffff;
    }
  }
}