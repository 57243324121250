// =========================
// Fonts
// =========================

@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@700&display=swap');

@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  src: url('../../fonts/Montserrat-Thin.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('../../fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url('../../fonts/Montserrat-Medium.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src: url('../../fonts/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  src: url('../../fonts/Montserrat-ExtraBold.ttf');
}