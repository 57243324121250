.section4 {
  .section-text-image__img {
    box-shadow: none;
    background-size: contain;
  }
  .button{
    display: none;
  }
}

.stats {
  height: 50vh;
  background-image: url('../../images/foto-sek5-paralax.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @include mq($until: md) {
    height: 40vw;
  }

  @include mq($until: sm) {
    height: 50vw;
  }

  @include mq($until: xs) {
    height: auto;
    padding: 30px 0;
  }

  &__text-container {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    justify-content: space-evenly;
    width: 100%;
    @include mq($until: sm){
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }
  }

  &__cell {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__number {
    color: white;
    font-size: 55px;
    font-weight: 900;
    margin-bottom: 10px;

    @include mq($until: md){
      font-size: 45px;
    }

    @include mq($until: sm) {
      font-size: 35px;
    }

    @include mq($until: xs) {
      font-size: 25px;
    }
  }

  &__text {
    color: white;
    font-size: 21px;
    font-weight: 600;
    @include mq($until: md){
      font-size: 18px;
    }
    @include mq($until: sm) {
      font-size: 15px;
    }

    @include mq($until: xs) {
      font-size: 10px;
    }
  }

  .button {
    font-weight: 600;
  }
}

.legend{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  height: 150px;
  &__item{
    width: 24%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transform: scaleY(0);
    transition: transform .3s ease-in-out;
    &:nth-of-type(1){
      background-color: #95ddf3;
      height: 20%;
    }
    &:nth-of-type(2){
      background-color: #29abe1;
      height: 50%;
    }
    &:nth-of-type(3){
      background-color: #2c67b4;
      height: 70%;
    }
    &:nth-of-type(4){
      background-color: #1f4691;
      height: 100%;
    }
    span{
      font-weight: 900;
      color: #FFFFFF;
      font-size: rem(38px);
      text-shadow: 0 0 5px rgba(0,0,0,0.3);
    }
  }
}

@keyframes heightOn {
  from {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
  to {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

.heightOn {
  -webkit-animation-name: heightOn;
  animation-name: heightOn;
  animation-duration: 500ms;
  transform-origin: bottom center;
}