.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100%;
  z-index: 9;
  min-height: 80px;
  margin-top: 50px;
  position: absolute;

  @include mq($until: md){
    margin-top: 15px;
  }

  @include mq($until: sm){
    margin-top: 5px;
  }
  @include mq($until: xs){
    min-height: 60px;
  }

  &--subsites{
    position: relative;
    background-color: #fff;
    margin-top: 0;
    padding-top: 10px;
    @include mq($until: lg){
      padding-top: 5px;
    }
    .header{
      &__menuList{
        justify-content: flex-end;
      }
      &__menuLink{
        color: #000;
        text-shadow: none;
        transition: color .3s ease-in-out;
        @include mq($until: md){
          color: #ffffff;
        }
        &:active, &--open, &--active, &:hover {
          color: $color-main;
          background-color: unset;
          @include mq($until: md){
            color: #FFFFFF;
          }
        }
      }
      &__nav{
        padding-right: 0;
        @include mq($from: md, $until: lg){
          padding-left: 15px;
          width: auto;
        }
      }
      &__logo{
        margin-left: 0;
        @include mq($until: lg){
          max-width: 150px;
          width: auto;
          padding-top: 5px;
        }
        @include mq($until: xs){
          max-width: 120px;
        }
      }
      &__hamburger{
        margin-right: 0;
        span{
          background-color: $color-main;
        }
      }
    }
  }

  &--fixed {
    @include mq($from: md){
      position: fixed;
      transition-property: background-color, box-shadow, margin-top;
      transition-duration: .3s;
      transition-timing-function: ease-in-out;
      &.sticky {
        background-color: white;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
        margin-top: 0;
        .header {
          &__menuLink {
            color: #000;
            text-shadow: none;
            transition: color .3s ease-in-out;
            @include mq($until: md) {
              color: #ffffff;
            }

            &:active, &--open, &--active, &:hover {
              color: $color-main;
              background-color: unset;
              @include mq($until: md) {
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }

  &--absolute {
    position: absolute;
  }

  &__nav{
    padding: 0 30px;
    width: 75%;
    @include mq($until: md){
      padding-left: $padding-lg;
      padding-right: $padding-lg;
    }
    @include mq($until: sm){
      padding-left: $padding-sm;
      padding-right: $padding-sm;
    }
    @include mq($until: xs){
      padding-left: $padding-xs;
      padding-right: $padding-xs;
    }
  }

  &__menuList {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__menuItem {
    margin: 0 5px;
    padding: 0;

    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: $color-font-light;
    transition: background-color .3s ease-in-out;
    padding: 0.3vw 0.6vw;
    font-size: 0.85vw;
    text-shadow: 0 0 15px rgba(0,0,0,1);

    @include mq($until: lg){
      font-size: 1vw;
    }
    @include mq($until: md){
      font-size: rem(16px);
    }

    &:active, &--open, &:hover {
      background-color: $color-main;
      color: $color-font-light;
    }
  }

  &__logo {
    max-width: 70%;
    @include mq($until: md){
        max-width: 150px;
    }
    @include mq($until: sm){
      margin-left: 15px;
    }
    @include mq($until: xs){
        max-width: 120px;
    }
  }
}