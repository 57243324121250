.faq {
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 10px 38px -9px rgba(0,0,0,0.25);
  transition: box-shadow .3s, transform .3s;
  background-color: $color-main;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    box-shadow: 0 10px 38px 0 rgba(0,0,0,0.35);
    transform: translateY(-20px);
  }

  @include mq($until: xl) {
    padding: 20px 15px;
  }



  h4 {
    color: white;
  }

  &__link {
    color: white;
    font-weight: 600;
    text-align: right;
  }

  &__excerpt {
    color: white;
    margin-bottom: 10px;
    @include mq($until: xl) {
      font-size: rem(14px);
    }
  }
}

