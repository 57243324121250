.subsite {
  margin-bottom: 50px;
  &__header {
    border-bottom: 1px solid $color-main;
    width: 100%;
    height: 35vw;
    display: flex;
    align-items: flex-end;
    margin-bottom: 40px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top -60px center;

    @include mq($until: lg) {
      height: 35vw;
    }

    @include mq($until: md){
      background-position: center;
    }

    @include mq($until: xs) {
      height: 40vw;
    }

    &-container {
      background-color: #fff;
      width: 100%;
      padding-top: 5vw;
      padding-bottom: 15px;
      display: flex;
      align-content: center;
      position: relative;
      @include mq($until: md){
        background-color: transparent;
        padding-top: 0;
        padding-bottom: 40px;
      }
      h1 {
        margin: 0;
        @include mq($until: lg){
          font-size: rem(26px);
        }
      }
    }
    &-img{
      position: absolute;
      bottom: 5.5vw;
      left: 50px;
      max-width: 15vw;
      @include mq($until: lg){
        left: 30px;
      }
      @include mq($until: md){
        display: none;
      }
    }
  }
}