body {
  background-color: $color-sections;
}

.section{
  height: 100vh;
  position: relative;

  @include mq($until: lg) {
    height: auto;
  }
}

.section-header {
  display: flex;
  align-items: flex-end;
  position: relative;

  @include mq($until: lg) {
    height: 50vw;
    margin-bottom: 50px;
  }

  @include mq($until: sm){
    height: 45vw;
  }
  @include mq($until: xs){
    height: 250px;
    margin-bottom: 30px;
  }

}
.section__owner{
  width: 20vw;
  max-width: unset;
  margin-left: 7vw;
  position: relative;
  z-index: 1;
  @include mq($until: lg){
    margin-left: 0;
  }
  @include mq($until: sm){
    margin-left: 15px;
  }
  @include mq($until: xs){
    display: none;
  }
}
.section__background-wrapper {
  display: block;
  position: absolute;
  right: 0;
  width: 100%;
  height: 100vh;
  @include mq($until: md){
    height: 100%;
  }
  @include mq($until: sm){
    width: 100%;
  }
}
.section__background {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  @include mq($until: sm){
    background-position: center;
  }
}
.header-hero{
  max-width: 80%;
  margin-bottom: 50px;
  @include mq($until: xs){
    margin-bottom: 0;
    max-width: 100%;
    padding: 0 15px;
  }
  &__container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
  }
  &__title{
    color: $color-font-light;
    font-size: 1.9vw;
    text-align: center;
    text-shadow: 0 0 15px rgba(0,0,0,1);
    @include mq($until: sm){
      font-size: rem(18px);
      text-shadow: 0 0 10px rgba(0,0,0,1);
    }
    @include mq($until: xs){
      font-size: rem(22px);
    }
  }

}

.section-text-image {
  width: 100%;
  background-color: $color-sections;
  display: flex;
  align-items: center;
  padding-top: 35px;
  padding-bottom: 35px;
  @include mq($until: xs){
    padding-top: 15px;
    padding-bottom: 15px;
  }

  p {
    line-height: 1.7;
    margin-bottom: 1rem;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10vw;

    @include mq($until: lg) {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 50px;
      column-gap: 50px;
    }
    @include mq($until: md) {
      grid-template-columns: 1fr;
      grid-row-gap: 50px;

    }
    @include mq($until: sm) {
      grid-template-columns: 1fr;
    }
    @include mq($until: xs){
      grid-row-gap: 30px;
    }
  }

  &__img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
    min-height: 35vw;
    align-self: center;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);

    @include mq($until: md) {
      grid-row: 1/2;
      min-height: 50vw;
    }


    &--left {
      grid-column: 1;
      grid-row: 1;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .button {
    align-self: flex-end;
  }
}

.section-full-screen-image {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: flex-end;
  position: relative;
  background-color: $color-sections;

  @include mq($until: lg) {
    flex-direction: column;
    justify-content: center;
  }
}

.contact-section {
  width: 100%;
  height: auto;
  min-height: 50vh;
  display: flex;
  align-items: flex-end;
  padding-bottom: 100px;
  padding-top: 100px;
  background-color: $color-sections;

  @include mq($until: lg) {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  h4{
    color: $color-font-dark;
  }

  &__content-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    min-height: 50vh;

    @include mq($until: lg) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1.5fr;
    }

    @include mq($until: xs) {
      grid-template-rows: 1fr 1fr;
    }

  }
  &__map-container {
    filter: grayscale(1);
    @include mq($until: lg) {
      margin-top: 50px;
    }
    @include mq($until: xs) {
      padding: 5px;
    }

    &--border {
      border: 1px solid $color-main;
      padding: 30px;
    }
  }

  &__social-media {
    display: flex;
    align-items: center;
  }

  .social-media__link {
    display: inline-block;
    margin-left: 10px;
    .svg-icon{
      fill: $color-font-dark;
      transition: fill .3s ease-in-out;
    }
    &:hover .svg-icon{
      fill: $color-main;
    }
  }

  &__contact {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 30px;
    padding: 30px 50px 30px 0;

    @include mq($until: xl) {
      grid-row-gap: 20px;
    }

    @include mq($until: lg) {
      grid-column-gap: 15px;
      grid-template-columns: repeat(2, min-content 1fr);
    }

    @include mq($until: sm) {
      grid-template-columns:  min-content 1fr;
    }
  }

  &__text {
    line-height: 1.8;

    &--email {
      display: flex;
      align-items: center;
      @include mq($until: lg) {
        grid-column: 4/5;
      }

      @include mq($until: sm) {
        grid-column: unset;
      }
    }
    &--phone{
      display: flex;
      align-items: center;
    }

    &--address {
      @include mq($until: lg) {
        grid-row: 1/3;
      }

      @include mq($until: sm) {
        grid-column: unset;
      }
    }
  }

  &__icon {
    width: 26px;

    img {
      width: 100%;
    }

    &--email {
      @include mq($until: lg) {
        grid-column: 3/4;
      }

      @include mq($until: sm) {
        grid-column: unset;
      }
    }

    &--address {
      @include mq($until: lg) {
        grid-row: 1/3;
      }

      @include mq($until: sm) {
        grid-column: unset;
      }
    }
  }
}



.svg-icon{
  width: 28px;
  fill: $color-main;
  display: flex;
  align-items: flex-start;
}